import { observer } from "mobx-react-lite";
import React, { FC, lazy, useContext } from "react";
import { authStore } from "./feature/auth/store/authStore";
import { retry } from "./feature/utils/retry";
import { OverlayLoader } from "./feature/ui/atoms/OveralyLoader";

const AuthRoutes = lazy(() =>
  retry(() =>
    import("./feature/auth/routes").then((module) => ({
      default: module.AuthRoutes,
    }))
  )
);
const AppRoutes = lazy(() =>
  retry(() =>
    import("./feature/routes").then((module) => ({
      default: module.AppRoutes,
    }))
  )
);

export const App: FC = observer(() => {
  const auth = useContext(authStore);

  if (auth.sessionId === null) {
    return <AuthRoutes />;
  }
  if (!auth.user) {
    return <OverlayLoader />;
  }
  if (auth.user && auth.user.role === "user") {
    auth.logout();
  }
  return <AppRoutes />;
});

export default App;
