import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./feature/utils/i18n";
import { OverlayLoader } from "./feature/ui/atoms/OveralyLoader";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster, throwErrorToast } from "./feature/ui/organisms/Toaster";
import { translator } from "./feature/utils/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const history = createBrowserHistory();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async () =>
        throwErrorToast(await translator.then((t) => t("common.smthWrong"))),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
    },
    mutations: {
      onError: async () =>
        throwErrorToast(await translator.then((t) => t("common.smthWrong"))),
    },
  },
});

root.render(
  <Router history={history}>
    <CssBaseline />
    <Suspense fallback={<OverlayLoader />}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <App />
          <Toaster />
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  </Router>
);
