import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

interface OverlayLoaderProps {
  offset?: number;
}

export const OverlayLoader: FC<OverlayLoaderProps> = ({ offset = 0 }) => (
  <Box
    display="flex"
    flexDirection="column"
    minHeight={`calc(100vh - ${offset}px)`}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress color="primary" sx={{ color: "#EB5161" }} />
  </Box>
);
