import reduceFields, { reduceList } from "../../reduceList";

const me = ["login", "role", "name"];

export const userDefault = [
  "login",
  "role",
  "name",
  "createDate",
  "isBlocked",
  "isDeleted",
  "scopes",
  "role",
  {
    travelerProfile: [
      "name",
      {
        photo: ["url"],
      },
      "kycStatus",
      "totalBookings",
      "orderCount",
    ],
  },
  {
    businessProfile: [
      "name",
      {
        photo: ["url"],
      },
      "kycStatus",
      "totalBookings",
      "orderCount",
      "description",
      "experiencesCount",
      "productsCount",
      {
        primaryCity: [
          {
            name: ["en", "ru"],
          },
          "country",
        ],
      },
      {
        businessCategories: [
          {
            name: ["en", "ru"],
          },
        ],
      },
    ],
  },
  {
    guideProfile: [
      "name",
      "presentation",
      {
        photo: ["url"],
      },
      "kycStatus",
      "totalBookings",
      "orderCount",
      "primaryLang",
      "secondaryLangs",
      "experiencesCount",
      "productsCount",
      {
        primaryCity: [
          {
            name: ["en", "ru"],
          },
          "country",
        ],
      },
    ],
  },
  {
    inspirerProfile: [
      "name",
      {
        photo: ["url"],
      },
      "kycStatus",
    ],
  },
];

export const listFields = reduceFields({
  items: userDefault,
});

export const userFields = reduceList(userDefault);

export const meFields = reduceList(me);
