import { get } from "../axiosConfig";
import { User } from "../../../types";
import { meFields } from "../fields/user";

export const MeAPI = {
  async getProfile(): Promise<User> {
    const params = {
      _fields: meFields,
    };
    const { data } = await get(`/api/v1/me`, { params });
    return data.result;
  },
};
