import { makeAutoObservable } from "mobx";
import { User } from "../../types";
import { createContext } from "react";
import { MeAPI } from "../../utils/api/requests/me-requests";

export class AppStore {
  sessionId: string | null = null;
  user: User | null = null;
  navBarOpen: boolean = true;

  constructor() {
    makeAutoObservable(this);
    this.load();
  }

  setSessionId = (id: string) => {
    this.sessionId = id;
    window.localStorage.setItem("sessionId", id);
    this.load();
  };

  load = () => {
    if (window.localStorage.getItem("navBarOpen") === "false") {
      this.navBarOpen = false;
    }

    this.sessionId = window.localStorage.getItem("sessionId");
    this.sessionId &&
      MeAPI.getProfile().then((user) => {
        this.setUser(user);
      });
  };

  setUser = (user: User) => {
    this.user = user;
  };

  logout = () => {
    this.sessionId = null;
    this.user = null;
    window.localStorage.removeItem("sessionId");
  };

  toggleNavBarOpen = () => {
    this.navBarOpen = !this.navBarOpen;
    window.localStorage.setItem("navBarOpen", this.navBarOpen.toString());
  };
}

export const AuthStoreInstance = new AppStore();
export const authStore = createContext(AuthStoreInstance);
